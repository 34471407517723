import * as React from "react";
import Select from "react-select";
import { InputLabel } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "firebaseConfig";

const WebLocationsLkp = ({ onChange, isDisabled ,selectedOptions }) => {

    const [data, setData] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState();

    React.useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "WebLocations"));

            const list = querySnapshot.docs.map(doc => ({
                value: doc.id,
                label: `${doc.data().title}`,
            }));

            setData(list);
        } catch (error) {
            console.error(error);
        }
    }

    const selectedOptionHandler = (selectedValues) => {
        setSelectedOption(selectedValues);
        onChange(selectedValues);
    }

    return (
        <>
            <InputLabel id="company-label" style={{ paddingBottom: '10px' }}>Web Locations</InputLabel>
            <Select
                className="form-control"
                value={selectedOptions}
                onChange={selectedOptionHandler}
                options={data}
                controlShouldRenderValue
                isMulti
                isClearable
                isDisabled={isDisabled}
            />
        </>
    )
}

export default WebLocationsLkp;
