// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "firebaseConfig";

// @mui material components
import Icon from "@mui/material/Icon";
import { isPropsEqual } from "@fullcalendar/react";


function DataTablePrintCell({ id,onItemDeleted }) {
  const navigate = useNavigate();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const onClickEditHandler = () => {
    navigate(`/portal/companyPage/edit/${id}`);
  };

  const onClickImageHandler = () => {
    navigate(`/portal/companyPage/image/${id}`);
  };

  const onClickSettingHandler = () => {
    navigate(`/portal/companyPage/setting/${id}`);
  };

  const onClickDeleteButtonHandler = () => {
    setShowDeleteAlert(true);
  };

  const onClickCanelDeleteHandler = () => {
    setShowDeleteAlert(false);
  };

  const onClickDeleteConfirmHandler = () => {

    deleteDoc(doc(db, "CompanyPages", id)).then(() => {
      setShowDeleteAlert(false);
      //onItemDeleted(id);
    }).catch((error) => {
      alert("Delete Error" + error);
    });
    

    //Tel Refresh Data
  };

  return (
    <>
  
        <Dialog
          open={showDeleteAlert}
          //onClose={handleClose}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this item?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              CompanyPage Id : {id} 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickCanelDeleteHandler} >Cancel</Button>
            <Button onClick={onClickDeleteConfirmHandler}  autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
    

    <MDBox
      component="td"
      textAlign="center"
      py={1.5}
      px={3}
      sx={({ typography: { size } }) => ({
        fontSize: size.sm,
        borderBottom: "none",
      })}
    >
      <MDButton
        component="a"
        onClick={onClickEditHandler}
        target="_blank"
        rel="noreferrer"
        variant="text"
        color="dark"
      >
        <Icon>edit</Icon>&nbsp;edit
      </MDButton>
      <MDButton
        component="a"
        onClick={onClickImageHandler}
        target="_blank"
        rel="noreferrer"
        variant="text"
        color="dark"
      >
        <Icon>image</Icon>&nbsp;image
      </MDButton>

      <MDButton
        component="a"
        onClick={onClickSettingHandler}
        target="_blank"
        rel="noreferrer"
        variant="text"
        color="dark"
      >
        <Icon>settings</Icon>&nbsp;setting
      </MDButton>

      <MDButton
        component="a"
        onClick={onClickDeleteButtonHandler}
        target="_blank"
        rel="noreferrer"
        variant="text"
        color="dark"
      >
        <Icon>delete</Icon>&nbsp;delete
      </MDButton>

      {/* <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        <a className="k-button k-flat k-button-icontext" href={url} target='_blank' >
          <span role="presentation" class="k-icon k-i-print"></span>Print
        </a>
      </MDBox> */}
    </MDBox>
    </>
  );
}

export default DataTablePrintCell;
