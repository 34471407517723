import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CrudDataTable from "examples/Tables/CrudDataTable";

function EmployeeList() {
  const API_URL = process.env.REACT_APP_API_URL;

  const columns = [
    { Header: "Id", accessor: "id", width: "5%" },
    { Header: "EmployeeNo", accessor: "employeeNo", width: "10%" },
    { Header: "First Name", accessor: "firstName", width: "15%" },
    { Header: "Last Name", accessor: "lastName", width: "15%" },
    { Header: "Company", accessor: "companyTitle", width: "15%" },
    { Header: "Division", accessor: "divisionTitle", width: "15%" },
    { Header: "Position", accessor: "position", width: "20%" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CrudDataTable API_URL={`${API_URL}employee/all`} route="/hr/employee" columns={columns} />
      <Footer />
    </DashboardLayout>
  );
}

export default EmployeeList;
