import * as React from "react";
import Select from "react-select";
import { InputLabel } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "firebaseConfig";

const ServiceCategoriesLkp = ({ onChange, companyRef, isDisabled }) => {

    const [data, setData] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState();

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        if (companyRef && data.length > 0) {
            const option = findOptionByValue(companyRef);
            if (option) setSelectedOption(option);
        }
    }, [companyRef, data]);

    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "ServiceCats"));

            const list = querySnapshot.docs.map(doc => ({
                value: doc.id,
                label: `${doc.data().title}`,
            }));

            setData(list);
        } catch (error) {
            console.error(error);
        }
    }

    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue);
        onChange(selectedValue);
    }

    const findOptionByValue = (value) => {
        return data.find(option => option.value === value);
    };

    return (
        <>
            <InputLabel id="company-label" style={{ paddingBottom: '10px' }}>Service Category</InputLabel>
            <Select
                className="form-control"
                value={selectedOption}
                onChange={selectedOptionHandler}
                options={data}
                controlShouldRenderValue
                isClearable
                isDisabled={isDisabled}
            />
        </>
    )
}

export default ServiceCategoriesLkp;
