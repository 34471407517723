/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from "assets/images/icons/flags/US.png";
import GB from "assets/images/icons/flags/GB.png";
import UAE from "assets/images/icons/flags/UAE.png";
import IQ from "assets/images/icons/flags/IQ.png";

const salesTableData = [
  {
    country: [US, "united state"],
    sales: "updating",
    value: "$-",
    bounce: "0.0%",
  },
  {
    country: [UAE, "united arab emirates"],
    sales: "updating",
    value: "$-",
    bounce: "0.0%",
  },
  {
    country: [GB, "united kingdom"],
    sales: "updating",
    value: "$-",
    bounce: "0.0%",
  },
  { country: [IQ, "Iraq"], sales: "updating", value: "$-", bounce: "0.0%" },
];

export default salesTableData;
