
//Todo Remove by firebase config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD31kFbqxOZ0Qo8_x2pTHD1yI1PgFkOFzs",
  authDomain: "cecbpm-33900.firebaseapp.com",
  projectId: "cecbpm-33900",
  storageBucket: "cecbpm-33900.appspot.com",
  messagingSenderId: "886631643251",
  appId: "1:886631643251:web:7e35bc63caf6d5b19f5206",
  measurementId: "G-1JV2CMRQ2Y",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { firebaseApp, auth };
