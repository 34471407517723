import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CrudDataTable from "examples/Tables/CrudDataTable";

function InventoryList() {
  const API_URL = process.env.REACT_APP_API_URL;

  const columns = [
    { Header: "Id", accessor: "id", width: "5%" },
    { Header: "Inventory Code", accessor: "inventoryCode", width: "20%" },
    { Header: "Type", accessor: "inventoryTypeTitle", width: "20%" },
    { Header: "Employee", accessor: "employeeTitle", width: "20%" },
    { Header: "Location", accessor: "locationTitle", width: "20%" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CrudDataTable API_URL={`${API_URL}inventory/all`} route="/adm/inventory" columns={columns} />
      <Footer />
    </DashboardLayout>
  );
}

export default InventoryList;
