import * as React from "react";
import Select from "react-select";
import Axios from "axios";
import { getIdToken } from "firebase/auth";
import { useContext } from "react";
import { AuthContext } from "AuthContext";
import { FormControl, InputLabel } from "@mui/material";

const API_URL = process.env.REACT_APP_API_URL;

const CompanyLkp = ({ onChange, companyRef, isDisabled }) => {
    const { currentUser } = useContext(AuthContext);

    const [data, setData] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState();

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        if (companyRef && data.length > 0) {
            const option = findOptionByValue(companyRef);
            if (option) setSelectedOption(option);
        }
    }, [companyRef, data]);

    const fetchData = async () => {
        try {
            const idToken = await getIdToken(currentUser);

            const response = await Axios.get(`${API_URL}Company/GetCompanyList/`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            });

            const list = response.data.map(item => ({
                value: item.id,
                label: `${item.preFixChar}-${item.title}`,
            }));

            setData(list);
        } catch (error) {
            console.error(error);
        }
    }

    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue);
        onChange(selectedValue);
    }

    const findOptionByValue = (value) => {
        return data.find(option => option.value === value);
    };

    return (
        <>
                <InputLabel id="company-label" style={{ paddingBottom: '10px' }}>Company</InputLabel>
                <Select
                    className="form-control"
                    value={selectedOption}
                    onChange={selectedOptionHandler}
                    options={data}
                    controlShouldRenderValue
                    isClearable
                    isDisabled={isDisabled}
                />
        </>
    )
}

export default CompanyLkp;
