/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export default function ProtectedRoute({ outlet, isPublic }) {
  const { currentUser } = useContext(AuthContext);
  const isAuthenticated = Boolean(currentUser);

  if (isPublic || isAuthenticated) {
    return outlet;
  }

  return <Navigate to={{ pathname: "/sign-in" }} />;
}
