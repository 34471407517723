import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import DataTable from "examples/Tables/DataTable";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import Axios from "axios";
import { getIdToken } from "firebase/auth";

import { AuthContext } from "AuthContext";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { Apartment } from "@mui/icons-material";

function CrudDataTable({ API_URL, route, columns }) {
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [currentId, setCurrentId] = useState(0);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    if (currentUser) {
      getIdToken(currentUser).then((idToken) => {
        Axios.get(
          `${API_URL}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          response.data.sort((a, b) => b.id - a.id);

          let data = response.data.map((item) => {
            return {
              ...item,
              _id: item.id  // copying id to _id
            }
          });

          const updatedColumns = [...columns]; // make a copy of the columns array
          updatedColumns.unshift({
            Header: "Actions",
            accessor: "_id",
            Cell: ({ value }) => (
              <div>
                <Button variant="text" color="info" size="small" onClick={() => handleEdit(value)}>Edit</Button>
                <Button variant="text" color="info" size="small" onClick={() => handleOpenDialog(value)}>Delete</Button>
                <Button variant="text" color="info" size="small" onClick={() => handleDetails(value)}>Details</Button>
              </div>
            ),
          });

          const dataTableData = {
            columns: updatedColumns, // use updatedColumns instead of columns
            rows: data,
          };
          setData(dataTableData);
          setTotal(data.length);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
      });
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentUser, API_URL, columns]);

  const handleAddNew = () => {
    navigate(route + "/add");
  };

  const handleEdit = (id) => {
    navigate(route + '/edit/' + id );
  };

  const handleOpenDialog = (id) => {
    setCurrentId(id);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleDelete = () => {
    handleCloseDialog();
    setIsLoading(true);
  
    console.log(currentId);



    if (currentUser) {
      getIdToken(currentUser).then((idToken) => {
        Axios.delete(
          `${getRootAPI_Url(API_URL)}/${currentId}`, 
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            }
          }
        )
        .then(response => {
          fetchData(); // Refetch data after delete
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        });
      });
    } else {
      console.log("No user logged in");
      setIsLoading(false);
    }
  };
  

  const handleDetails = (id) => {
    navigate(route + '/detail/' + id);
  };

  const getRootAPI_Url = (api_url) => {
    if(api_url.endsWith('/all')) {
      api_url = api_url.replace('/all', '');
    }
    return api_url;
  };

  return (
    <MDBox pt={6} pb={3}>
       <DeleteConfirmationDialog 
        open={openDialog}
        onConfirm={handleDelete}
        onCancel={handleCloseDialog}
      />
      <MDBox mb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Button variant="contained" color="dark" onClick={handleAddNew}>+ Add New</Button>
          </MDBox>
          {isLoading ? (
            <CircularProgress /> // replace <div>Loading...</div> with CircularProgress
          ) : (
            data && <DataTable table={data} canSearch />
          )}
        </Card>
      </MDBox>

      {(total === 0 && !isLoading) &&
        <MDAlert color="warning">
          <MDTypography variant="body2" color="white">
            No records found.
          </MDTypography>
        </MDAlert>
      }
    </MDBox>
  );
}

export default CrudDataTable;
