import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { AuthContext } from "AuthContext";

import EmployeeInfo from "layouts/pages/employees/employeeDetail/components/EmployeeInfo";

function EmployeeDetail() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const isEdit = id > 0;

  const [formValues, setFormValues] = useState({
    name: "",
    url: "",
    owner: "",
    isPrimary: false,
    userCredentials: "",
    description: "",
  });


  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit) {
        const token = await currentUser.getIdToken();
        const response = await axios.get(`${API_URL}employee/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setFormValues(response.data);
        } else {
          setErrorMessage(`Error:${response.status}`);
        }
      }
    };
    fetchData();
  }, [isEdit, id, currentUser, API_URL]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {`Employee Details [${id}]`}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <EmployeeInfo formValues={formValues} />
              </MDBox>
              <MDBox p={2}>
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/hr/employee")}>
                  Back
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmployeeDetail;
