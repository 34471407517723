import { useState, useEffect } from "react";
//Navigation
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import 'react-quill/dist/quill.snow.css';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "firebaseConfig";
import { useParams } from "react-router-dom";
import ReactCrop from "react-easy-crop";
import getCroppedImg from '../../../components/cropImage/cropImageHelper'

function CompanyPageImage() {
  const navigate = useNavigate();


  const COLLECTION_NAME = "CompanyPages";
  const IMAGE_PATH = "/images/companyPage/";
  const CANCEL_PATH = "/portal/companyPage";

  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState(null);

  const [upImage, setUpImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropping, setCropping] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUpImage(file);
    const localImageUrl = URL.createObjectURL(file);

    setImageUrl(localImageUrl);
    setCropping(true);
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImageBlob = await getCroppedImg(
      imageUrl,
      croppedAreaPixels,
      1800,
      800
    );

    // Convert the blob to File object to upload
    const croppedFile = new File([croppedImageBlob], upImage.name, {
      type: upImage.type,
    });

    setUpImage(croppedFile);
    console.log(croppedFile);
    // Revoke local image URL to free up resources
    URL.revokeObjectURL(imageUrl);
    //setCropping(false);
  };

  useEffect(() => {
    console.log("id", id);

    getDoc(doc(db, COLLECTION_NAME, id)).then((doc) => {
      if (doc.exists()) {
        setImageUrl(doc.data().imageUrl);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });

  }, []);

  const uploadImageHandler = () => {

    //chcek if image size is greater than 1MB
    if (upImage.size > 1000000) {
      alert("Image size is greater than 1MB");
      return;
    }

    //Get image extension
    const extension = upImage.name.split(".").pop();


    const storageRef = ref(
      storage,
      //`/images/${Date.now()}${upImage.name}`
      `${IMAGE_PATH + id + "." + extension}`
    );

    const uploadImage = uploadBytesResumable(storageRef, upImage);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {


        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          //update emai
          updateDoc(doc(db, COLLECTION_NAME, id), {
            imageUrl: url,
          });
          setImageUrl(url);
          setCropping(false);
        });
      }
    );
  };

  const cancelHander = () => {
    navigate(CANCEL_PATH);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Upload Company Page Image
                </MDTypography>
              </MDBox>
            </MDBox>


            {(cropping && imageUrl) && (
              <Card>
                <MDBox p={2}>
                  <MDBox style={{ height: "300px" }}
                  >
                    <ReactCrop

                      image={imageUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={9 / 4}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </MDBox>
                </MDBox>
              </Card>
            )}

            <Card>
              <MDBox p={2}>
                <MDBox>

                  {(!cropping && imageUrl) && (
                    <MDBox
                      component="img"
                      src={imageUrl}
                      //alt="Product Image"
                      borderRadius="lg"
                      shadow="sm"
                      // width="100%"
                      height="300px"
                      position="relative"
                      zIndex={10}
                      mb={2}
                    />
                  )}

                  <MDBox>
                    <MDTypography variant="h5">Media</MDTypography>
                    <MDBox mt={3}>
                      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                          Resource Image
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">

                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => handleImageChange(e)}
                        />
                        {upImage && (
                          <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={uploadImageHandler}
                          >
                            upload
                          </MDButton>
                        )}

                        {progress === 0 ? null : (
                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-striped mt-2"
                              style={{ width: `${progress}%` }}
                            >
                              {`uploading image ${progress}%`}
                            </div>
                          </div>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Back
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default CompanyPageImage;
