import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/employees/employeeForm/components/FormField";
import CompanyLkp from "components/Lookups/Companies/CompanyLkp";
import { InputLabel } from "@mui/material";
import DivisionLkp from "components/Lookups/Deivisions/DivisionLkp";

function EmployeeInfo({ formValues, onInputChange }) {
  const handleInputChange = (name, value) => {
    onInputChange(name, value);
  };

  const companyLkpHandler = (selectedValue) => {
    if (selectedValue) {
      handleInputChange("companyRef", selectedValue.value);
    } else {
      handleInputChange("companyRef", 0);
    }
  };

  const divisionLkpHandler = (selectedValue) => {
    if (selectedValue) {
      handleInputChange("divisionRef", selectedValue.value);
    } else {
      handleInputChange("divisionRef", 0);
    }
  };

  return (
    <MDBox component="form">
      <MDBox mt={3}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6} >
            <FormField name="firstName" value={formValues.firstName ?? ""} onChange={(e) => handleInputChange('firstName', e.target.value)} type="text" label="First Name" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField name="lastName" value={formValues.lastName ?? ""} onChange={(e) => handleInputChange('lastName', e.target.value)} type="text" label="Last Name" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompanyLkp name="companyRef" onChange={companyLkpHandler} companyRef={formValues.companyRef} ></CompanyLkp>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DivisionLkp name="divisionRef" onChange={divisionLkpHandler} divisionRef={formValues.divisionRef} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField name="position" value={formValues.position ?? ""} onChange={(e) => handleInputChange('position', e.target.value)} type="text" label="Position" required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField name="nationality" value={formValues.nationality ?? ""} onChange={(e) => handleInputChange('nationality', e.target.value)} type="text" label="nationality" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField name="idNumber" value={formValues.idNumber ?? ""} onChange={(e) => handleInputChange('idNumber', e.target.value)} type="text" label="idNumber" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField name="employeeNo" value={formValues.employeeNo ?? ""} onChange={(e) => handleInputChange('employeeNo', e.target.value)} type="text" label="employeeNo" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <input name="dateOfEmployment" value={formValues.dateOfEmployment?.split("T")[0] ?? ""}
              onChange={(e) => handleInputChange('dateOfEmployment', e.target.value)} type="date" label="dateOfEmployment" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default EmployeeInfo;
