import React from 'react';
import { getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import MDButton from 'components/MDButton';

function LoginByMicrosoft365Btn() {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const db = getFirestore();

  const onClick_BtnMicrosoftLogin = (e) => {
    e.preventDefault();
    const auth = getAuth();

    const provider = new OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      tenant: '964bda13-b277-4242-90a2-bb4969d0b192'
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const token = result._tokenResponse.idToken;

        // Microsoft's access token
        const microsoftAccessToken = credential.accessToken;

        // Making a request to Microsoft Graph API for user's photo
        axios
          .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: { Authorization: 'Bearer ' + microsoftAccessToken },
            responseType: 'blob'
          })
          .then((response) => {
            const reader = new FileReader();
            reader.onload = () => {
              const photoDataURL = reader.result;

              // Save the image data URL in Firestore
              const userRef = doc(collection(db, 'users'), result.user.uid);
              setDoc(userRef, { photoURL: photoDataURL }, { merge: true })
                .then(() => {
                  console.log('Image URL saved in Firestore');
                  navigate('/');
                })
                .catch((error) => {
                  console.log('Error saving image URL in Firestore:', error);
                });
            };
            reader.readAsDataURL(response.data);
          })
          .catch((error) => {
            console.log('Error retrieving user photo:', error);
          });

        axios
          .post(
            API_URL + 'Auth/AddUser',
            {
              ProviderId: result.providerId,
              Uid: result.user.uid,
              Email: result.user.email
            },
            { headers: { Authorization: 'Bearer ' + token } }
          )
          .then((response) => {
            navigate('/');
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MDButton variant="gradient" color="info" size="large" fullWidth onClick={onClick_BtnMicrosoftLogin}>
      Sign in with Microsoft 365
    </MDButton>
  );
}

export default LoginByMicrosoft365Btn;
