/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
//import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
import TenderList from "layouts/pages/tenders/tenderPortals/TenderList";
//import TenderForm from "layouts/pages/tenders/tenderPortalForm/index";


// Material Dashboard 2 PRO React components
//import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
//import profilePicture from "assets/images/team-3.jpg";
//import TenderPortalDetail from "layouts/pages/tenders/tenderPortalDetail";
import EmployeeList from "layouts/pages/employees/employees/EmployeeList";
import EmployeeDetail from "layouts/pages/employees/employeeDetail";
import EmployeeForm from "layouts/pages/employees/employeeForm";

import InventoryList from "layouts/pages/inventories/inventories/InventoryList";
import InventoryDetail from "layouts/pages/inventories/inventoryDetail";
import InventoryForm from "layouts/pages/inventories/inventoryForm";
import ArticleList from "layouts/articles/ArticleList";
import ArticleAdd from "layouts/articles/new-article/ArticleAdd";
import ArticleImage from "layouts/articles/new-article/ArticleImage";
import ArticleSetting from "layouts/articles/new-article/ArticleSetting";
import ServiceCatList from "layouts/serviceCats/ServiceCatList";
import ServiceCatAdd from "layouts/serviceCats/new-serviceCat/ServiceCatAdd";
import ServiceCatImage from "layouts/serviceCats/new-serviceCat/ServiceCatImage";
import ServiceList from "layouts/services/ServiceList";
import ServiceAdd from "layouts/services/new-service/ServiceAdd";
import ServiceImage from "layouts/services/new-service/ServiceImage";
import ServiceSetting from "layouts/services/new-service/ServiceSetting";
import WebLocationList from "layouts/webLocations/WebLocationList";
import WebLocationAdd from "layouts/webLocations/new-webLocation/WebLocationAdd";
import { Article } from "@mui/icons-material";
import CompanyPageCatList from "layouts/companyPageCats/CompanyPageCatList";
import CompanyPageCatAdd from "layouts/companyPageCats/new-companyPageCat/CompanyPageCatAdd";
import CompanyPageCatImage from "layouts/companyPageCats/new-companyPageCat/CompanyPageCatImage";
import CompanyPageList from "layouts/companyPages/CompanyPageList";
import CompanyPageAdd from "layouts/companyPages/new-companyPage/CompanyPageAdd";
import CompanyPageImage from "layouts/companyPages/new-companyPage/CompanyPageImage";
import CompanyPageSetting from "layouts/companyPages/new-companyPage/CompanyPageSetting";
import ResourceCatList from "layouts/resourceCats/ResourceCatList";
import ResourceCatAdd from "layouts/resourceCats/new-resourceCat/ResourceCatAdd";
import ResourceCatImage from "layouts/resourceCats/new-resourceCat/ResourceCatImage";
import ResourceList from "layouts/resources/ResourceList";
import ResourceAdd from "layouts/resources/new-resource/ResourceAdd";
import ResourceImage from "layouts/resources/new-resource/ResourceImage";
import ResourceSetting from "layouts/resources/new-resource/ResourceSetting";
import ResourcePdf from "layouts/resources/new-resource/ResourcePdf";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Brooklyn Alice",
  //   key: "brooklyn-alice",
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
  //     {
  //       name: "My Profile",
  //       key: "my-profile",
  //       route: "/pages/profile/profile-overview",
  //       component: <ProfileOverview />,
  //     },
  //     {
  //       name: "Settings",
  //       key: "profile-settings",
  //       route: "/pages/account/settings",
  //       component: <Settings />,
  //     },
  //     {
  //       name: "Logout",
  //       key: "logout",
  //       route: "/authentication/sign-in/basic",
  //       component: <SignInBasic />,
  //     },
  //   ],
  // },
  { type: "divider", key: "divider-0", isProduction: true, isMenu: true },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        isProduction: true,
        isMenu: true,
        component: <Analytics />,
      },
      // {
      //   name: "Sales",
      //   key: "sales",
      //   route: "/dashboards/sales",
      //   component: <Sales />,
      // },
    ],
  },
  { type: "title", title: "Modules", key: "title-modules", isProduction: true },
  {
    type: "collapse",
    name: "Tenders",
    key: "tendersMenus",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "Portals",
        key: "tender-portals",
        route: "/tender/tender-portals",
        isProduction: true,
        component: <TenderList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Human Resources",
    key: "hrMenus",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "Employees",
        key: "employee",
        route: "/hr/employee",
        isProduction: true,
        component: <EmployeeList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employeesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "New Employee",
        key: "employee-add",
        route: "/hr/employee/add",
        isProduction: true,
        component: <EmployeeForm />,
      },
      {
        name: "Edit Employee",
        key: "employee-edit",
        route: "/hr/employee/edit/:id",
        isProduction: true,
        component: <EmployeeForm />,
      },
      {
        name: "Employee Details",
        key: "employee-detail",
        route: "/hr/employee/detail/:id",
        isProduction: true,
        component: <EmployeeDetail />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Administration",
    key: "admMenus",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "Inventories",
        key: "inventory",
        route: "/adm/inventory",
        isProduction: true,
        component: <InventoryList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Inventories",
    key: "inventoriesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">article page</Icon>,
    collapse: [
      {
        name: "New Inventory",
        key: "inventory-add",
        route: "/adm/inventory/add",
        isProduction: true,
        component: <InventoryForm />,
      },
      {
        name: "Edit Inventory",
        key: "inventory-edit",
        route: "/adm/inventory/edit/:id",
        isProduction: true,
        component: <InventoryForm />,
      },
      {
        name: "Inventory Details",
        key: "inventory-detail",
        route: "/adm/inventory/detail/:id",
        isProduction: true,
        component: <InventoryDetail />,
      },
    ],
  },


  {
    type: "collapse",
    name: "CRM",
    key: "crmMenus",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "Portal Contact Us",
        key: "portalContactUs",
        route: "/crm/contacyus",
        isProduction: true,
        component: <Article />,
      },
    ],
  },


  {
    type: "collapse",
    name: "Portal",
    key: "portalMenu",
    isProduction: true,
    isMenu: true,
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        name: "Web Locations",
        key: "weblocations",
        route: "/portal/webLocation",
        isProduction: true,
        component: <WebLocationList />,
      },
      {
        name: "Service Categories",
        key: "serviceCats",
        route: "/portal/serviceCat",
        isProduction: true,
        component: <ServiceCatList />,
      },
      {
        name: "Services",
        key: "service",
        route: "/portal/service",
        isProduction: true,
        component: <ServiceList />,
      },
      {
        name: "Company Page Categories",
        key: "companyPageCat",
        route: "/portal/companyPageCat",
        isProduction: true,
        component: <CompanyPageCatList />,
      },
      {
        name: "Company Pages",
        key: "companyPage",
        route: "/portal/companyPage",
        isProduction: true,
        component: <CompanyPageList />,
      },
      {
        name: "Careers",
        key: "career",
        route: "/portal/career",
        isProduction: true,
        component: <ArticleList />,
      },
      {
        name: "Resource Categories",
        key: "resourceCats",
        route: "/portal/resourceCat",
        isProduction: true,
        component: <ResourceCatList />,
      },
      {
        name: "Resources",
        key: "resources",
        route: "/portal/resources",
        isProduction: true,
        component: <ResourceList />,
      },
      {
        name: "Office & Labs",
        key: "officeLab",
        route: "/portal/officeLab",
        isProduction: true,
        component: <ArticleList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Inventories",
    key: "inventoriesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">article pages</Icon>,
    collapse: [

      {
        name: "New Article",
        key: "article-add",
        route: "/portal/article/add",
        isProduction: true,
        component: <ArticleAdd />,
      },
      {
        name: "Edit Article",
        key: "article-edit",
        route: "/portal/article/edit/:id",
        isProduction: true,
        component: <ArticleAdd />,
      },
      {
        name: "Setting Article",
        key: "article-image",
        route: "/portal/article/image/:id",
        isProduction: true,
        component: <ArticleImage />,
      },
      {
        name: "Edit Inventory",
        key: "article-setting",
        route: "/portal/article/setting/:id",
        isProduction: true,
        component: <ArticleSetting />,
      },
    ],
  },

  {
    type: "collapse",
    name: "webLocation",
    key: "webLocationPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">webLocation pages</Icon>,
    collapse: [
      {
        name: "New WebLocation",
        key: "webLocation-add",
        route: "/portal/webLocation/add",
        isProduction: true,
        component: <WebLocationAdd />,
      },
      {
        name: "Edit ServiceCat",
        key: "webLocation-edit",
        route: "/portal/webLocation/edit/:id",
        isProduction: true,
        component: <WebLocationAdd />,
      },
    ],
  },

  {
    type: "collapse",
    name: "service",
    key: "servicesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">service pages</Icon>,
    collapse: [
      {
        name: "New serviceCat",
        key: "serviceCat-add",
        route: "/portal/serviceCat/add",
        isProduction: true,
        component: <ServiceCatAdd />,
      },
      {
        name: "Edit ServiceCat",
        key: "serviceCat-edit",
        route: "/portal/serviceCat/edit/:id",
        isProduction: true,
        component: <ServiceCatAdd />,
      },
      {
        name: "Image ServiceCat",
        key: "serviceCat-image",
        route: "/portal/serviceCat/image/:id",
        isProduction: true,
        component: <ServiceCatImage />,
      },

      {
        name: "New service",
        key: "service-add",
        route: "/portal/service/add",
        isProduction: true,
        component: <ServiceAdd />,
      },
      {
        name: "Edit service",
        key: "service-edit",
        route: "/portal/service/edit/:id",
        isProduction: true,
        component: <ServiceAdd />,
      },
      {
        name: "Setting service",
        key: "service-image",
        route: "/portal/service/image/:id",
        isProduction: true,
        component: <ServiceImage />,
      },
      {
        name: "Edit Inventory",
        key: "service-setting",
        route: "/portal/service/setting/:id",
        isProduction: true,
        component: <ServiceSetting />,
      },
    ],
  },
  {
    type: "collapse",
    name: "resource",
    key: "resourcesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">resource pages</Icon>,
    collapse: [
      {
        name: "New resourceCat",
        key: "resourceCat-add",
        route: "/portal/resourceCat/add",
        isProduction: true,
        component: <ResourceCatAdd />,
      },
      {
        name: "Edit ResourceCat",
        key: "resourceCat-edit",
        route: "/portal/resourceCat/edit/:id",
        isProduction: true,
        component: <ResourceCatAdd />,
      },
      {
        name: "Image ResourceCat",
        key: "resourceCat-image",
        route: "/portal/resourceCat/image/:id",
        isProduction: true,
        component: <ResourceCatImage />,
      },

      {
        name: "New resource",
        key: "resource-add",
        route: "/portal/resource/add",
        isProduction: true,
        component: <ResourceAdd />,
      },
      {
        name: "Edit resource",
        key: "resource-edit",
        route: "/portal/resource/edit/:id",
        isProduction: true,
        component: <ResourceAdd />,
      },
      {
        name: "Setting resource",
        key: "resource-image",
        route: "/portal/resource/image/:id",
        isProduction: true,
        component: <ResourceImage />,
      },
      {
        name: "resource pdf",
        key: "resource-pdf",
        route: "/portal/resource/pdf/:id",
        isProduction: true,
        component: <ResourcePdf />,
      },
      {
        name: "Edit Inventory",
        key: "resource-setting",
        route: "/portal/resource/setting/:id",
        isProduction: true,
        component: <ResourceSetting />,
      },
    ],
  },
  {
    type: "collapse",
    name: "companyPage",
    key: "companyPagesPages",
    isProduction: true,
    isMenu: false,
    icon: <Icon fontSize="medium">companyPage pages</Icon>,
    collapse: [
      {
        name: "New companyPageCat",
        key: "companyPageCat-add",
        route: "/portal/companyPageCat/add",
        isProduction: true,
        component: <CompanyPageCatAdd />,
      },
      {
        name: "Edit CompanyPageCat",
        key: "companyPageCat-edit",
        route: "/portal/companyPageCat/edit/:id",
        isProduction: true,
        component: <CompanyPageCatAdd />,
      },
      {
        name: "Image CompanyPageCat",
        key: "companyPageCat-image",
        route: "/portal/companyPageCat/image/:id",
        isProduction: true,
        component: <CompanyPageCatImage />,
      },

      {
        name: "New companyPage",
        key: "companyPage-add",
        route: "/portal/companyPage/add",
        isProduction: true,
        component: <CompanyPageAdd />,
      },
      {
        name: "Edit companyPage",
        key: "companyPage-edit",
        route: "/portal/companyPage/edit/:id",
        isProduction: true,
        component: <CompanyPageAdd />,
      },
      {
        name: "Setting companyPage",
        key: "companyPage-image",
        route: "/portal/companyPage/image/:id",
        isProduction: true,
        component: <CompanyPageImage />,
      },
      {
        name: "Edit Inventory",
        key: "companyPage-setting",
        route: "/portal/companyPage/setting/:id",
        isProduction: true,
        component: <CompanyPageSetting />,
      },
    ],
  },

  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    isMenu: false,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/sign-in",
            isPublic: true,
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            isPublic: true,
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Docs", key: "title-docs" },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "License",
            key: "license",
            href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  },
  {
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
];

export default routes;
