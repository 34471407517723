import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Axios from "axios";
import MDButton from 'components/MDButton';

function LoginByGoogle() {
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL;

    const onClick_BtnGoogleLogin = (e) => {
        e.preventDefault();
        const auth = getAuth();

        const provider = new GoogleAuthProvider();
        //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = result._tokenResponse.idToken;
                const user = result.user;

                axios.post(API_URL + "Auth/AddUser",
                    {
                        ProviderId: result.providerId,
                        Uid: result.user.uid,
                        Email: result.user.email
                    },
                    { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => {
                        navigate('/');
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }).catch((error) => {
                console.log(error);
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    return (
        <MDButton variant="gradient" color="error" size="large" fullWidth onClick={onClick_BtnGoogleLogin}>
        Sign in with Google
      </MDButton>
    );
};

export default LoginByGoogle;

