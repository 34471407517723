import * as React from "react";
import AsyncSelect from "react-select/async";
import { InputLabel } from "@mui/material";
import { collection, getDocs, query, orderBy, startAt, endAt } from "firebase/firestore";
import { db } from "firebaseConfig";

const ResourcesLkp = ({ onChange, isDisabled, selectedOptions }) => {
  const [selectedOption, setSelectedOption] = React.useState();

  const fetchOptions = async (inputValue) => {
    if (inputValue.length < 3) {
      return []; // Return an empty array if the input is less than 3 characters
    }

    try {
      const startValue = inputValue;
      const endValue = inputValue + "\uf8ff";

      const queryRef = query(
        collection(db, "Resources"),
        orderBy("title"),
        startAt(startValue),
        endAt(endValue)
      );

      const querySnapshot = await getDocs(queryRef);

      const list = querySnapshot.docs
        .map((doc) => ({
          value: doc.id,
          label: `${doc.data().title}`,
        }))
        .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())); // client-side filtering

      return list;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const selectedOptionHandler = (selectedValues) => {
    setSelectedOption(selectedValues);
    onChange(selectedValues);
  };

  return (
    <>
      <InputLabel id="company-label" style={{ paddingBottom: "10px" }}>
        Resources (Type 3 First Characters, Case Sensitive)
      </InputLabel>
      <AsyncSelect
        className="form-control"
        value={selectedOptions}
        loadOptions={fetchOptions}
        onChange={selectedOptionHandler}
        controlShouldRenderValue
        isMulti
        isClearable
        isDisabled={isDisabled}
      />
    </>
  );
};

export default ResourcesLkp;




// import * as React from "react";
// import Select from "react-select";
// import { InputLabel } from "@mui/material";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "firebaseConfig";

// const ResourcesLkp = ({ onChange, isDisabled ,selectedOptions }) => {

//     const [data, setData] = React.useState([]);
//     const [selectedOption, setSelectedOption] = React.useState();

//     React.useEffect(() => {
//         fetchData();
//     }, []);


//     const fetchData = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(db, "Resources"));

//             const list = querySnapshot.docs.map(doc => ({
//                 value: doc.id,
//                 label: `${doc.data().title}`,
//             }));

//             setData(list);
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     const selectedOptionHandler = (selectedValues) => {
//         setSelectedOption(selectedValues);
//         onChange(selectedValues);
//     }

//     return (
//         <>
//             <InputLabel id="company-label" style={{ paddingBottom: '10px' }}>Web Locations</InputLabel>
//             <Select
//                 className="form-control"
//                 value={selectedOptions}
//                 onChange={selectedOptionHandler}
//                 options={data}
//                 controlShouldRenderValue
//                 isMulti
//                 isClearable
//                 isDisabled={isDisabled}
//             />
//         </>
//     )
// }

// export default ResourcesLkp;
