/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

//Navigation
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { addDoc, collection, updateDoc,doc,getDoc, query, where, getDocs} from "firebase/firestore";
import { db } from "firebaseConfig";
import { CATEGORIES } from "data/dummyDatas";
import slugify from 'slugify';
import { wait } from "@testing-library/user-event/dist/utils";


function WebLocationAdd(props) {
  const navigate = useNavigate();
  debugger;
  const { id } = useParams();
  const isEdit = id != null;
  
  debugger;

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  const [page, setPage] = useState("");
  const [warnings, setWarnings] = useState([]);
  const [titleError, setTitleError] = useState(false);
  

  const onPageChange = (html) => {
    setPage(html);
  }

  const onTitleChange = (e) => {
    setTitleError(e.target.value == "");
    setTitle(e.target.value);
    setUrl(slugify(e.target.value, { lower: true }));
  }
  const onDescriptionChange = (e) => {
    setDescriptionError(e.target.value == "");
    setDescription(e.target.value);
  }

  useEffect(() => { 
    if (isEdit) {

      getDoc(doc(db, "WebLocations", id)).then((doc) => {
        if (doc.exists()) {
          setTitle(doc.data().title);
          setDescription(doc.data().description);
          setPage(doc.data().page);
          setUrl(doc.data().url);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    } 
    
  }, []);

  const checkIfUnique = async (title, url, ignoreId) => {
    const titleQuery = query(collection(db, 'WebLocations'), where('title', '==', title));
    const urlQuery = query(collection(db, 'WebLocations'), where('url', '==', url));
  
    const titleDocs = (await getDocs(titleQuery)).docs;
    const urlDocs = (await getDocs(urlQuery)).docs;
  
    const titleDuplicate = titleDocs.some(doc => doc.id !== ignoreId);
    const urlDuplicate = urlDocs.some(doc => doc.id !== ignoreId);
  
    if(titleDuplicate || urlDuplicate) {
      setWarnings(prevWarnings => [...prevWarnings, { id: Date.now(), text: 'Title or URL is not unique' }]);
      return false;
    }
  
    return true;
  }
  
  
  

  const onAddEditArticle = () => {  
    setWarnings([]);
    if (title === "") {
      setWarnings((currentItems) => [...currentItems, { text: "Enter Title", id: Math.random().toString() }]);
      setTitleError(true);
      return;
    }


    if (isEdit) {
      Edit();
    } else {
      Add();
    };

  };

  const Add = async () => {
    const articleRef = collection(db,"WebLocations");

    const isUnique = await checkIfUnique(title, url);
  
    if(!isUnique) return;
    
    //Add Edit
    addDoc(articleRef, { 
      title, 
      url: url,
      description,
      createdAt: new Date(),
    }).then((e) => {
      navigate("/portal/webLocation/");
    });
  }

  const Edit = async () => {

    const isUnique = await checkIfUnique(title, url,id);
    if(!isUnique) return;
    
    updateDoc( doc(db, "WebLocations", id), {
        title: title,
        url: url,
        description: description,
        modfiedAt: new Date(),
      }).then(() => {
      navigate("/portal/webLocation");
    });
  }

  const cancelHander = () => {
    navigate("/portal/webLocation");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Web Location
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5">Web Location Information</MDTypography>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDInput
                            type="text"
                            label="Title"
                            required
                            error= {titleError}
                            variant="standard"
                            fullWidth
                            onChange={onTitleChange}
                            value={title}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <p>Url: /{url}</p>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDInput
                            type="text"
                            label="Description"
                            required
                            error= {descriptionError}
                            variant="standard"
                            fullWidth
                            onChange={onDescriptionChange}
                            value={description}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={3}  >

                    {warnings && warnings.map((warning) => {
                      return (
                        <MDAlert color="warning" key={warning.id}>{warning.text}</MDAlert>
                      );
                    }
                    )}
                  </MDBox>

                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Cancel
                    </MDButton>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={onAddEditArticle}
                      >
                        OK
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default WebLocationAdd;
