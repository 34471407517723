/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//Navigation
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { addDoc, collection, updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { CATEGORIES } from "data/dummyDatas";

function ResourceSetting(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [published, setPublished] = useState(false);
  const [feathered, setFeathered] = useState(false);


  useEffect(() => {

    getDoc(doc(db, "Resources", id)).then((doc) => {
      if (doc.exists()) {
        setPublished(doc.data().published);
        setFeathered(doc.data().feathered);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });


  }, []);



  const onEditArticle = () => {
    updateDoc(doc(db, "Resources", id), {
      published: published,
      feathered: feathered,
      modfiedAt: new Date(),
    }).then(() => {
      navigate("/portal/resource");
    });
  };

  const cancelHander = () => {
    navigate("/portal/resource");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Resource Settings
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5">Resource Settings</MDTypography>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                            <MDBox mt={0.5}>
                              <Switch checked={published} onChange={() => setPublished(!published)} />
                            </MDBox>
                            <MDBox width="80%" ml={0.5}>
                              <MDTypography variant="button" fontWeight="regular" color="text">
                                Published
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                            <MDBox mt={0.5}>
                              <Switch checked={feathered} onChange={() => setFeathered(!feathered)} />
                              </MDBox>
                            <MDBox width="80%" ml={0.5}>
                              <MDTypography variant="button" fontWeight="regular" color="text">
                                feathered
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>



                  </MDBox>
                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Cancel
                    </MDButton>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={onEditArticle}
                      >
                        OK
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ResourceSetting;
