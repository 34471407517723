/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//Navigation
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import slugify from 'slugify';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { addDoc, collection, updateDoc, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "firebaseConfig";
import ResourceCategoriesLkp from "components/Lookups/ResourceCategories/ResourceCategoriesLkp";
import ServiceCategoriesLkp from "components/Lookups/ServiceCategories/ServiceCategoriesLkp";
import WebLocationsLkp from "components/Lookups/WebLocations/WebLocationsLkp";

function ResourceAdd(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = id != null;

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  const [page, setPage] = useState("");
  const [resourceCategoryRef, setResourceCategoryRef] = useState(null);
  const [serviceCategoryRef, setServiceCategoryRef] = useState(null);

  const [relatedWebLocations , setRelatedWebLocations] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [titleError, setTitleError] = useState(false);


  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  const onPageChange = (html) => {
    setPage(html);
  }

  const onTitleChange = (e) => {
    setTitleError(e.target.value == "");
    setTitle(e.target.value);
    setUrl(slugify(e.target.value, { lower: true }));
  }



  useEffect(() => {
    if (isEdit) {

      getDoc(doc(db, "Resources", id)).then((doc) => {
        if (doc.exists()) {
          setTitle(doc.data().title);
          setDescription(doc.data().description);
          setPage(doc.data().page);
          setResourceCategoryRef(doc.data().resourceCategoryRef);
          setServiceCategoryRef(doc.data().serviceCategoryRef);
          setUrl(doc.data().url);
          setRelatedWebLocations(doc.data().relatedWebLocations);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    } else {
      setResourceCategoryRef(1);
    }


  }, []);

  const resourceCategoryLkpHandler = (selectedValue) => {
    if (selectedValue) {
      setResourceCategoryRef(selectedValue.value);
    } else {
      setResourceCategoryRef(0);
    }
  };

  const serviceCategoryLkpHandler = (selectedValue) => {
    if (selectedValue) {
      setServiceCategoryRef(selectedValue.value);
    } else {
      setServiceCategoryRef(0);
    }
  };

  const webLocationLkpHandler = (selectedValue) => {
      setRelatedWebLocations(selectedValue);
  };

  const onAddEditArticle = () => {

    if (title === "") {
      setWarnings((currentItems) => [...currentItems, { text: "Enter Title", id: Math.random().toString() }]);
      setTitleError(true);
      return;
    }


    if (isEdit) {
      Edit();
    } else {
      Add();
    };

  };

  const Add = () => {
    const articleRef = collection(db, "Resources");

    //Add Edit
    addDoc(articleRef, {
      title,
      resourceCategoryRef: resourceCategoryRef,
      serviceCategoryRef: serviceCategoryRef,
      description,
      page,
      url,
      relatedWebLocations,
      createdAt: serverTimestamp(),
      published: false,
      feathered: false
    }).then((e) => {
      navigate("/portal/resource/");
    });
  }

  const Edit = () => {

    updateDoc(doc(db, "Resources", id), {
      title: title,
      resourceCategoryRef: resourceCategoryRef,
      serviceCategoryRef: serviceCategoryRef,
      description: description,
      page: page,
      url: url,
      relatedWebLocations,
      modfiedAt: serverTimestamp(),
    }).then(() => {
      navigate("/portal/resource");
    });
  }

  const cancelHander = () => {
    navigate("/portal/resource");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Resource
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5">Resource Information</MDTypography>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDInput
                            type="text"
                            label="Title"
                            required
                            error={titleError}
                            variant="standard"
                            fullWidth
                            onChange={onTitleChange}
                            value={title}
                          />
                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <p>Url: resource/{url}</p>
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <MDInput
                                  type="text"
                                  label="Description"
                                  required
                                  error={descriptionError}
                                  variant="standard"
                                  fullWidth
                                  onChange={onDescriptionChange}
                                  value={description}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mb={3} mt={3}>
                            <ResourceCategoriesLkp 
                              name="resourceCategoryRef" 
                              onChange={resourceCategoryLkpHandler} 
                              companyRef={resourceCategoryRef} />
                          </MDBox>
                          <MDBox mb={3} mt={3}>
                            <ServiceCategoriesLkp 
                              name="serviceCategoryRef" 
                              onChange={serviceCategoryLkpHandler} 
                              companyRef={serviceCategoryRef} />
                          </MDBox>
                          <MDBox mb={3} mt={3}>
                            <WebLocationsLkp 
                              name="webLocationRef" 
                              onChange={webLocationLkpHandler} 
                              selectedOptions={relatedWebLocations} />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                          >
                            Page
                          </MDTypography>
                          <ReactQuill theme="snow" value={page}
                            onChange={onPageChange}
                            style={{ height: '400px', marginBottom: '30pt' }} />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={3}  >

                    {warnings && warnings.map((warning) => {
                      return (
                        <MDAlert color="warning" key={warning.id}>{warning.text}</MDAlert>
                      );
                    }
                    )}
                  </MDBox>




                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Cancel
                    </MDButton>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={onAddEditArticle}
                      >
                        OK
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ResourceAdd;
