// cropImageHelper.js

export default function getCroppedImg(imageSrc, crop, outputWidth, outputHeight) {
  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // If output dimensions aren't provided, default to crop dimensions
  outputWidth = outputWidth || crop.width;
  outputHeight = outputHeight || crop.height;

  canvas.width = outputWidth;
  canvas.height = outputHeight;
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    outputWidth,
    outputHeight
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
}
