import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CompanyLkp from "components/Lookups/Companies/CompanyLkp";
import DivisionLkp from "components/Lookups/Deivisions/DivisionLkp";

function InventoryInfo({ formValues }) {
  return (
    <MDBox mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MDBox mt={3}>
            <MDTypography variant="body1" component="p">
              First Name: {formValues.firstName}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDBox mt={3}>
            <MDTypography variant="body1" component="p">
              Last Name: {formValues.lastName}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyLkp name="companyRef" companyRef={formValues.companyRef} isDisabled={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DivisionLkp name="divisionRef" divisionRef={formValues.divisionRef}  isDisabled={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography variant="body1" component="p">
            Position: {formValues.position}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography variant="body1" component="p">
            Nationality: {formValues.nationality}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography variant="body1" component="p">
            IDNumber: {formValues.idNumber}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography variant="body1" component="p">
            EmployeeNo: {formValues.employeeNo}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography variant="body1" component="p">
            Date Of Employment: {
              (formValues.dateOfEmployment) ? 
                new Intl.DateTimeFormat('en-GB').format(new Date(formValues.dateOfEmployment)) 
                : '-'
            }
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default InventoryInfo;
