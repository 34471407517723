import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import { AuthContext } from "AuthContext";

import InventoryInfo from "layouts/pages/inventories/inventoryForm/components/InventoryInfo";

function NewInventory() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const isEdit = id > 0;

  const [formValues, setFormValues] = useState({
    name: "",
    url: "",
    owner: "",
    isPrimary: false,
    userCredentials: "",
    description: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const { currentUser } = useContext(AuthContext);

  const handleInputChange = (name, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit) {
        const token = await currentUser.getIdToken();
        const response = await axios.get(`${API_URL}employee/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setFormValues(response.data);
        } else {
          setErrorMessage(`Error:${response.status}`);
        }
      }
    };
    fetchData();
  }, [isEdit, id, currentUser, API_URL]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");

    const token = await currentUser.getIdToken();
    let response;

    try {
      if (isEdit) {
        response = await axios.put(`${API_URL}employee/${id}`, formValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.post(`${API_URL}employee`, formValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (response.status === 200 || response.status === 201 || response.status === 204) {
        navigate("/hr/employee");
      } else {
        setErrorMessage(`Error:${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("Error response data:", error.response.data);
        if (Array.isArray(error.response.data)) {
          setErrorMessage(error.response.data.map((e) => `${e.name}: ${e.message}`).join("\n"));
        } else {
          setErrorMessage(JSON.stringify(error.response.data));
        }
      } else if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unknown error occurred");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar /> 
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {isEdit ? `Edit Inventory [${id}]` : `Add New Inventory`}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <form onSubmit={handleSubmit}>
                <MDBox mt={-3} mb={3} mx={2}>
                  <InventoryInfo formValues={formValues} onInputChange={handleInputChange} />
                </MDBox>
                <MDBox p={2}>
                  <MDBox>
                    <MDBox mt={3} width="100%" display="flex" justifyContent="right">
                      <MDButton
                        variant="gradient"
                        color="light"
                        style={{ marginRight: "10px" }}
                        onClick={() => navigate("/hr/employee")}
                      >
                        Cancel
                      </MDButton>
                      <MDButton variant="gradient" color="dark" type="submit">
                        OK
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox p={2}>
                  {errorMessage && (
                    <MDAlert color="warning">
                      <ul style={{ padding: "20px" }}>
                        {errorMessage.split("\n").map((error) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </MDAlert>
                  )}
                </MDBox>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewInventory;
