import React from "react";

// Firebase
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import LoginByGoogle from "./LoginByGoogle";
import LoginByMicrosoft365Btn from "./LoginByMicrosoft365Btn";

function Illustration() {

  return (
    <IllustrationLayout
      title="Sign In"
      description="Sign in with your CEC Offical Account"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mt={4} mb={1}>
          <LoginByMicrosoft365Btn></LoginByMicrosoft365Btn>
        </MDBox>
        <MDBox mt={3} mb={1}>
          {/* <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleSignInWithGoogle}>
            Sign in with Google
          </MDButton> */}
          {/* <LoginByGoogle></LoginByGoogle> */}
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
