/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//Navigation
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { addDoc, collection, updateDoc,doc,getDoc} from "firebase/firestore";
import { db } from "firebaseConfig";
import { CATEGORIES } from "data/dummyDatas";

function ArticleAdd(props) {
  const navigate = useNavigate();
  debugger;
  const { id } = useParams();
  const isEdit = id != null;
  
  debugger;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categoryRef, setCategoryRef] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [titleError, setTitleError] = useState(false);

  const onDescriptionChange = (html) => {
    setDescription(html);
  }

  const onTitleChange = (e) => {
    setTitleError(e.target.value == "");
    setTitle(e.target.value);
  }

  const categories = CATEGORIES.map((category) => {
    return {
      label: category.title,
      id: category.id,
    };
  });

  useEffect(() => { 
    if (isEdit) {

      getDoc(doc(db, "Articles", id)).then((doc) => {
        if (doc.exists()) {
          setTitle(doc.data().title);
          setDescription(doc.data().description);
          setCategoryRef(doc.data().categoryRef);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    } else {
      setCategoryRef(1);
    }
    

  }, []);

  const onCategoryChange = (e, value) => {
    setCategoryRef(value.id);
  }

  const onAddEditArticle = () => {  

    if (title === "") {
      setWarnings((currentItems) => [...currentItems, { text: "Enter Title", id: Math.random().toString() }]);
      setTitleError(true);
      return;
    }


    if (isEdit) {
      Edit();
    } else {
      Add();
    };

  };

  const Add = () => {
    const articleRef = collection(db,"Articles");
    
    //Add Edit
    addDoc(articleRef, { 
      title, 
      categoryRef: categoryRef,
      description,
      createdAt: new Date(),
      published: false,
      feathered: false
    }).then((e) => {
      navigate("/portal/article/");
    });
  }

  const Edit = () => {
    
    updateDoc( doc(db, "Articles", id), {
        title: title,
        categoryRef: categoryRef,
        description: description,
        modfiedAt: new Date(),
      }).then(() => {
      navigate("/portal/article");
    });
  }

  const cancelHander = () => {
    navigate("/portal/article");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Article
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5">Article Information</MDTypography>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDInput
                            type="text"
                            label="Title"
                            required
                            error= {titleError}
                            variant="standard"
                            fullWidth
                            onChange={onTitleChange}
                            value={title}
                          />
                          <MDBox mb={3} mt={3}>
                            <MDBox mb={2} display="inline-block">
                              <MDTypography
                                component="label"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                textTransform="capitalize"
                              >
                                Category
                              </MDTypography>
                            </MDBox>
                            { categoryRef != null &&
                            <Autocomplete
                              defaultValue={categories[categoryRef - 1]}
                              autoSelect={true}
                              chan
                              options={categories}
                              onChange={onCategoryChange}
                              disableClearable
                              renderInput={(params) => <MDInput {...params} variant="standard" />}
                            />
                            }
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                          >
                            Description
                          </MDTypography>
                          <ReactQuill theme="snow" value={description} 
                            onChange={onDescriptionChange}
                            style={{ height: '400px', marginBottom: '30pt' }}  />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={3}  >

                    {warnings && warnings.map((warning) => {
                      return (
                        <MDAlert color="warning" key={warning.id}>{warning.text}</MDAlert>
                      );
                    }
                    )}
                  </MDBox>


                  

                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Cancel
                    </MDButton>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={onAddEditArticle}
                      >
                        OK
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ArticleAdd;
