import { getFirestore, doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

class FirebaseHelper {
  async getUserPhotoURL(currentUser) {
    debugger;
    if (!currentUser) {
      return ""; // Return null if current user is null
    }

    const providerId = currentUser.providerData[0]?.providerId;

    if (providerId !== 'microsoft.com') {
      console.log(currentUser.photoURL);
      return currentUser.photoURL; // Return photoURL for providers other than Microsoft
    }

    try {
      const db = getFirestore();
      const userRef = doc(collection(db, 'users'), currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const { photoURL } = userData;
        console.log(photoURL);
        return photoURL;
      }
    } catch (error) {
      console.log('Error retrieving user photo URL:', error);
    }

    return null; // Return null if photo URL is not found
  }

  getUserPhotoURLRealtime(currentUser, setPhotoURL) {
    if (!currentUser) {
      setPhotoURL("");
      return; // Return if current user is null
    }

    const providerId = currentUser.providerData[0]?.providerId;

    if (providerId !== 'microsoft.com') {
      setPhotoURL(currentUser.photoURL); // Set photoURL for providers other than Microsoft
      return;
    }

    try {
      const db = getFirestore();
      const userRef = doc(collection(db, 'users'), currentUser.uid);

      // Set up a real-time listener
      onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const { photoURL } = doc.data();
          setPhotoURL(photoURL); // Update photo URL in real-time
        }
      });
    } catch (error) {
      console.log('Error setting up real-time listener:', error);
    }
  }

}

export default FirebaseHelper;
