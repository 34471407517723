import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTableCommandCell from "layouts/serviceCats/DataTableCommandCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

// Firebase
import { getAuth, getIdToken } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "firebaseConfig";

import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";

function ServiceCatList() {
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = () => {

    const serviceCatRef = collection(db, "ServiceCats");
    const q = query(serviceCatRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const articlesDate = snapshot.docs.map((doc) => ({
        id: doc.id,
        editId: doc.id,
        ...doc.data(),
      }));

      const dataTableData = {
        columns: [
          { Header: "Id", accessor: "id", width: "10%" },
          { Header: "Title", accessor: "title", width: "40%" },
          // { Header: "Category", accessor: "categoryRef", width: "25%" },
          // {
          //   Header: "feathered",
          //   accessor: "feathered",
          //   with: "10%",
          //   Cell: ({ value }) => {
          //     let status = <></>;
          //     if (value)
          //       status = <StatusCell icon="home" color="success" status="Feathred" />
          //     return status;
          //   },
          // },
          {
            Header: "Commands",
            accessor: "editId",
            width: "25%",
            Cell: ({ value }) => DataTableCommandCell({ id: value }),
          },
          // {
          //   Header: "Image",
          //   accessor: "id",
          //   width: "25%",
          //   Cell: ({ value }) => DataTablePrintCell({ url: value }),
          // },
          // {
          //   Header: "Setting",
          //   accessor: "id",
          //   width: "25%",
          //   Cell: ({ value }) => DataTablePrintCell({ url: value }),
          // },
          // {
          //   Header: "Delete",
          //   accessor: "id",
          //   width: "25%",
          //   Cell: ({ value }) => DataTablePrintCell({ url: value }),
          // },
        ],
        rows: articlesDate
      };

      setData(dataTableData);
      setTotal(articlesDate.length);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addHandler = () => {
    navigate('/portal/serviceCat/add');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDButton variant="gradient" color="info" onClick={addHandler} >
              new service categorie
            </MDButton>
          </MDBox>
          {(total > 0 && !isLoading) &&

            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Service Categories
                </MDTypography>
              </MDBox>
              {data &&
                <DataTable table={data} canSearch />
              }
            </Card>
          }
        </MDBox>
      </MDBox>


      {(total === 0 && !isLoading) &&
        <MDAlert color="warning">
          <MDTypography variant="body2" color="white">
            No records found.
          </MDTypography>
        </MDAlert>
      }

      <Footer />
    </DashboardLayout>
  );
}

export default ServiceCatList;
