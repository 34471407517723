import { useState, useEffect, useContext } from "react";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Button from "@mui/material/Button";
import MDButton from "components/MDButton";
// Axios
import CrudDataTable from "examples/Tables/CrudDataTable";
import { AuthContext } from "AuthContext";

function TenderList() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { currentUser } = useContext(AuthContext);

  const columns = [
    //{ Header: "Id", accessor: "id", width: "5%" },
    { Header: "Name", accessor: "name", width: "15%" },
    {
      Header: "URL",
      accessor: "url",
      width: "15%",
      Cell: ({ value }) => (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          title={value}
        >
          {value.length > 50 ? value.substring(0, 50) + "..." : value}
        </a>
      ),
    },
    { Header: "Owner", accessor: "owner", width: "15%" },
    {
      Header: "Is Primary",
      accessor: "isPrimary",
      width: "10%",
      Cell: ({ value }) => value ? 'True' : '',
      sortType: (rowA, rowB, columnId) => {
        return rowA.original[columnId] === rowB.original[columnId]
          ? 0
          : rowA.original[columnId]
            ? -1
            : 1;
      },
    },
    {
      Header: "User Credentials",
      accessor: "userCredentials",
      width: "20%",
      Cell: ({ value }) => {
        const [isCopied, setIsCopied] = useState(false);

        const handleCopyToClipboard = () => {
          navigator.clipboard.writeText(value);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1500);
        };

        return (
          <>
            <span>{value} </span>
            <button onClick={handleCopyToClipboard}>
              {isCopied ? "Copied!" : "Copy"}
            </button>
          </>
        );
      },
    },
    //{ Header: "Description", accessor: "description", width: "25%" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CrudDataTable API_URL={`${API_URL}tenderportal`} route="/tender/tender-portals" columns={columns} />
      <Footer />
    </DashboardLayout>
  );
}

export default TenderList;
