/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
//Navigation
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { CATEGORIES } from "data/dummyDatas";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "firebaseConfig";
import { useParams } from "react-router-dom";

function ResourcePdf() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);

  const [upPdf, setUpPdf] = useState(null);
  const [progress, setProgress] = useState(0);


  const handlePdfChange = (e) => {
    setUpPdf(e.target.files[0]);
    //setFormData({ ...formData, pdf: e.target.files[0] });
    //setFormData({ ...formData, pdf: e.target.files[0] });
  };

  useEffect(() => {
    console.log("id", id);

    getDoc(doc(db, "Resources", id)).then((doc) => {
      if (doc.exists()) {
        setPdfUrl(doc.data().pdfUrl);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });

  }, []);

  const uploadPdfHandler = () => {

    //chcek if pdf size is greater than 10MB
    if (upPdf.size > 20000000) {
      alert("Pdf size is greater than 20MB");
      return;
    }

    //Get pdf extension
    const extension = upPdf.name.split(".").pop();


    const storageRef = ref(
      storage,
      //`/pdfs/${Date.now()}${upPdf.name}`
      `/pdfs/resource/${id + "." + extension}`
    );

    const uploadPdf = uploadBytesResumable(storageRef, upPdf);

    uploadPdf.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        //setFormData({
        //title: "",
        //description: "",
        //pdf: "",
        //});

        getDownloadURL(uploadPdf.snapshot.ref).then((url) => {
          //update emai
          updateDoc(doc(db, "Resources", id), {
            pdfUrl: url,
          });
          setPdfUrl(url);

        });
      }
    );
  };

  //Test update Data
  //Show Current Photo


  const cancelHander = () => {
    navigate("/portal/resources");
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Upload Resource Pdf
                </MDTypography>
              </MDBox>
            </MDBox>


            <Card>
              <MDBox p={2}>
                <MDBox>

                  {pdfUrl && (
                    pdfUrl
                  )}


                  <MDBox>
                    <MDTypography variant="h5">PDF FILE</MDTypography>




                    <MDBox mt={3}>
                      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                          Resource Pdf
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">

                        <input
                          type="file"
                          name="pdf"
                          accept="pdf/*"
                          className="form-control"
                          onChange={(e) => handlePdfChange(e)}
                        />
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={uploadPdfHandler}
                          disabled={upPdf === null}
                        >
                          upload
                        </MDButton>

                        {progress === 0 ? null : (
                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-striped mt-2"
                              style={{ width: `${progress}%` }}
                            >
                              {`uploading pdf ${progress}%`}
                            </div>
                          </div>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>



                  <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end" >
                    <MDButton variant="gradient" color="light" ml={20} mr={20} onClick={cancelHander} >
                      Back
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ResourcePdf;
